import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Card, CardContent, CardActions, Button} from '@material-ui/core';
import AdIconFilled from './adicon-filled';

import { BooleanParam, useQueryParam } from "use-query-params"
import CookieManager from "./cookie"
import { Close } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  ad: {
    backgroundImage: 'url("/assets/ad320x50.png")', 
    backgroundRepeat: 'round', 
    width: '100%',
    height: '50px',
    padding: '0',
    maxHeight: '90px',
    maxWidth: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'url("/assets/ad728x90.png")',
      height: '90px',
      maxWidth: '728px'
    },
  },

  hiddenDiv: {
    display: 'none'
  }
}));

const keyToEnable = "adPlacement"; // used in cookie and queryparams
const keyToDisable = "disableAdPlacement"; // used in cookie and queryparams

//this logic is moved here, only becuase this is used when people try to buy ads!
const cm = typeof window === 'object' ? CookieManager() : undefined;

const AdPlacement = ({ title }) => {
  const classes = useStyles();
  const adTitle = title ? title : "Your partner in relocation";

  const [adPlacement, setAdPlacement] = useQueryParam(keyToEnable, BooleanParam)
  const [disableAdPlacement, setDisableAdPlacement] = useQueryParam(keyToDisable, BooleanParam)


  const isInCookie = () => {
    return cm && cm.getCookie(keyToEnable);
  }
  
  const showAdPlacement = adPlacement || isInCookie();
  if (disableAdPlacement) {
    cm.setCookie(keyToEnable, adPlacement, 0); //reset
  } else if (showAdPlacement) {
    cm.setCookie(keyToEnable, adPlacement, 1/24/60*1); //10 min
  }

  //const [visible, setVisible] = useState(showAdPlacement) // true is the inital state

  useEffect( () => {
    const adPlacementDiv = document && document.getElementById("adPlacementDiv");

    if (disableAdPlacement) {
      if (adPlacementDiv){
        adPlacementDiv.style.display = 'none';
      }
    } else if (showAdPlacement && !disableAdPlacement) {
      if (adPlacementDiv){
        adPlacementDiv.style.display = 'block';
      }
    }
  }, []); // no listener value specified, so it reacts as componentDidMount


  return (
  <div id='adPlacementDiv' className={classes.hiddenDiv}>
    <Card style={{background: '#6574c4', }}>
      <CardContent>
      <Chip style={{color: 'white', border: '1px solid white', }}
          icon={<AdIconFilled style={{color: 'white', paddingLeft: '3px' }} background='#6574c4'/>}
          label={adTitle}
          variant="outlined"
        />
      </CardContent>
      <CardContent style={{flexFlow: 'column', display: 'flex'}}>
        <div className={classes.ad}>
        </div>
      </CardContent>
      <CardActions disableSpacing style={{justifyContent: 'flex-end', }}>
      <Button
        className={classes.postJobButtonReUse}
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<Close />}
        onClick={
          event => {
              event.stopPropagation();
              if (cm) {
                cm.setCookie(keyToEnable, adPlacement, 0); //reset
              }

              const adPlacementDiv = document && document.getElementById("adPlacementDiv");
              if (adPlacementDiv){
                adPlacementDiv.style.display = 'none';
              }
              //setVisible(false); // trigger re-render --- not working
              navigate(`?${keyToDisable}=1`);
          }
        }
      >
        Hide
      </Button>
      </CardActions>
    </Card>
    <br />
  </div> 
  );
}

export default AdPlacement
