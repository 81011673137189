import SvgIcon from '@material-ui/core/SvgIcon';

function AdIconFilled(props) {
    return (
      <SvgIcon {...props} viewBox="-10 0 317 210" >
      <path
		 fill="currentColor"
		 stroke="currentColor"
		 stroke-width="15"
         id="rect10"
         d="M 42.665338,-0.75595123 H 255.93586 c 23.2178,0 41.90939,18.69158623 41.90939,41.90938523 V 167.48942 c 0,23.2178 -18.69159,41.90938 -41.90939,41.90938 H 42.665338 c -23.2178,0 -41.90938552,-18.69158 -41.90938552,-41.90938 V 41.153434 c 0,-23.217799 18.69158552,-41.90938523 41.90938552,-41.90938523 z" />
      <path
		 fill={props.background}
		 stroke="currentColor"
         d="M 77.88783,150.73695 V 76.620547 q 0,-8.68164 6.201171,-14.882812 6.250782,-6.250781 14.932422,-6.250781 h 21.232817 q 8.68164,0 14.88281,6.250781 6.25078,6.250781 6.25078,14.882812 v 74.116403 h -21.13359 v -42.3664 H 99.021423 v 42.3664 z M 99.021423,97.85336 H 120.25424 V 76.620547 q 0,-4.316015 -3.175,-7.391796 -3.12539,-3.125391 -7.44141,-3.125391 -4.36563,0 -7.49102,3.125391 -3.125387,3.075781 -3.125387,7.391796 z"
         id="path52" />
      <path
		 fill={props.background}
		 stroke="currentColor"
         d="M 162.47181,150.73695 V 55.486954 h 42.36641 q 8.68164,0 14.88281,6.250781 6.25078,6.250781 6.25078,14.882812 v 52.982813 q 0,8.58242 -6.25078,14.88281 -6.20117,6.25078 -14.88281,6.25078 z m 21.1336,-10.6164 h 10.6164 q 4.41524,0 7.49102,-3.12539 3.12539,-3.12539 3.12539,-7.3918 V 76.620547 q 0,-4.316015 -3.175,-7.391796 -3.12539,-3.125391 -7.44141,-3.125391 h -10.6164 z"
         id="path54" />
      </SvgIcon>
    );
  }

  export default AdIconFilled
